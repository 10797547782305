import ApiService from "./ApiService";

export async function createGroups(data) {
  return ApiService.fetchData({
    url: "/admin-groups/creategroup",
    method: "post",
    data,
  });
}

export async function DeleteGroups(data) {
  return ApiService.fetchData({
    url: `/admin-groups/${data}`,
    method: "delete",
  });
}

export async function AddUserToGroup(groupId, userId) {
  return ApiService.fetchData({
    url: `/admin-groups/${groupId}/users/${userId}`,
    method: "post",
  });
}

export async function getGroupUserBelongs(userId) {
  console.log("getGroupUserBelongs", userId);
  return ApiService.fetchData({
    url: `/admin-groups/group/users/${userId}`,
    method: "get",
  });
}

export async function RemoveUserFromGroup(groupId, userId) {
  return ApiService.fetchData({
    url: `/admin-groups/${groupId}/users/${userId}`,
    method: "delete",
  });
}
