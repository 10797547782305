import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import group from "./group/groupSlice";
import users from "./users/UsersSlice";
import admins from "./admins/adminSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    group,
    users,
    admins,
    auth,
    base,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
