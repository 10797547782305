import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { getGroupUserBelongs } from "services/GroupService";

export const fetchGroups = createAsyncThunk(
  "group/fetchGroups",

  async () => {
    return ApiService.fetchData({
      url: "/admin-groups/getall",
      method: "get",
    });
  }
);

export const fetchUserGroup = createAsyncThunk(
  "group/fetchUserGroup",
  async (userId) => {
    return await getGroupUserBelongs(userId);
  }
);

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    group: [],
    usergroup: [],
    status: "idle",
  },
  reducers: {
    SUCCESS_CREATE_GROUP: (state, action) => {
      console.log("action @ SUCCESS_CREATE_GROUP", action);
      state.status = "success";
      state.group = [...state.group, action.payload];
    },

    FAILED_CREATE_GROUP: (state, action) => {
      state.status = "failed";
      state.group = [];
    },

    SUCCESS_DELETE_GROUP: (state, action) => {
      state.status = "success";
      const filteredData = state.group.filter((r) => r.id !== action.payload);
      state.group = filteredData;
    },

    SUCCESS_ADD_USER_TO_GROUP: (state, action) => {
      console.log("action @ SUCCESS_CREATE_GROUP", action);
      state.status = "success";
      state.usergroup = [...state.usergroup, action.payload];
    },
    SUCCESS_DELETE_USER_FROM_GROUP: (state, action) => {
      state.status = "success";
      const filteredData = state.usergroup.filter(
        (r) => r.groupId !== action.payload
      );
      state.usergroup = filteredData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = "success";
        state.group = action.payload.data;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.status = "failed";
        state.group = [];
      })

      .addCase(fetchUserGroup.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserGroup.fulfilled, (state, action) => {
        state.status = "success";
        state.usergroup = action.payload.data;
      })
      .addCase(fetchUserGroup.rejected, (state, action) => {
        state.status = "failed";
        state.usergroup = [];
      });
  },
});

export const fetchAllGroups = (state) => state.group.group;

export const fetchUserInGroupById = (state) => state.group.usergroup;

export const {
  SUCCESS_CREATE_GROUP,
  SUCCESS_DELETE_GROUP,
  SUCCESS_ADD_USER_TO_GROUP,
  SUCCESS_DELETE_USER_FROM_GROUP,
} = groupSlice.actions;

export default groupSlice.reducer;
