import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUsers, getUserById } from "services/UsersService";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",

  async () => {
    return await getAllUsers();
  }
);

export const fetchUserById = createAsyncThunk(
  "users/fetchUserById",

  async (userId) => {
    return await getUserById(userId);
  }
);

export const UsersSlice = createSlice({
  name: "users",
  initialState: {
    allusers: [],
    user: {},
    status: "idle",
  },
  reducers: {
    SUCCESS_FETCH_USERS: (state, action) => {
      console.log("action @ SUCCESS_FETCH_USERS", action);
      state.status = "success";
      state.user = [...state.user, ...action.payload];
    },

    FAILED_FETCH_USERS: (state, action) => {
      state.status = "failed";
      state.user = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "success";
        console.log("ss", action.payload);
        state.allusers = action.payload.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.allusers = [];
      })

      .addCase(fetchUserById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = "success";
        console.log("fetchUserById", action.payload);
        state.user = action.payload.data;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = "failed";
        state.user = {};
      });
  },
});

export const selectAllUsers = (state) => state.users.allusers;
export const selectUsersById = (state) => state.users.user;

export const { SUCCESS_FETCH_USERS, SUCCESS_DELETE_USERS } = UsersSlice.actions;

export default UsersSlice.reducer;
