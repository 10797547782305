import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";

export const fetchAdmin = createAsyncThunk(
  "admin/fetchAdmin",

  async () => {
    return ApiService.fetchData({
      url: "/admin/adminusers",
      method: "get",
    });
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    admin: [],
    status: "idle",
  },
  reducers: {
    SUCCESS_CREATE_ADMIN: (state, action) => {
      console.log("action @ SUCCESS_CREATE_ADMIN", action);
      state.status = "success";
      state.admin = [...state.admin, action.payload];
    },

    FAILED_CREATE_ADMIN: (state, action) => {
      state.status = "failed";
      state.admin = [];
    },

    SUCCESS_DELETE_ADMIN: (state, action) => {
      state.status = "success";
      const filteredData = state.admin.filter((r) => r.id !== action.payload);
      state.admin = filteredData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admin = action.payload.data;
      })
      .addCase(fetchAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.admin = [];
      });
  },
});
export const fetchAllAdmin = (state) => state.admins.admin;

export const { SUCCESS_CREATE_ADMIN, SUCCESS_DELETE_ADMIN } =
  adminSlice.actions;

export default adminSlice.reducer;
