import ApiService from "./ApiService";

export async function uploadKYC(data) {
  const formData = new FormData();

  formData.append("user", data.get("user"));
  formData.append("idPicture", data.get("idPicture"));
  formData.append("idType", data.get("idType"));
  formData.append("idNumber", data.get("idNumber"));

  console.log("iamg", formData.get("idPicture"));
  console.log("iamg 2", formData.get("user"));
  console.log("user Id>>>>>", data.get("userId"));
  const userId = data.get("userId");

  return ApiService.fetchData({
    url: `/admin/upload/${userId}`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
    },
  });
}

export async function getKYC(userId = "46d8abca-0a04-48e8-a619-1d12f23f7994") {
  return ApiService.fetchData({
    url: `/admin/upload/${userId}`,
    method: "GET",
  });
}

// export async function DeleteUsers(data) {
//   return ApiService.fetchData({
//     url: `/admin/${data}`,
//     method: "delete",
//   });
// }

export async function getAllUsers() {
  return ApiService.fetchData({
    url: "/admin/users",
    method: "get",
  });
}

export async function getUserById(userId) {
  return ApiService.fetchData({
    url: `/admin/user/${userId}`,
    method: "get",
  });
}
